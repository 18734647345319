import axios from 'axios';
import API_URL from '../backend'
import authHeader from './auth-header.service';


class ServiciosBloquesService {
    getTodos(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/serviciosBloques/todos',
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    actualizarBloque(bloque){
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/admin/serviciosBloques/update',
            data:bloque,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    nuevoBloque(bloque){
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/admin/serviciosBloques/nuevo',
            data:bloque,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    borrarBloque(bloqueId){
        const axiosRequest = {
            method: 'delete',
            url: API_URL+'/admin/serviciosBloques/borrar/'+bloqueId,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }

}
export default new ServiciosBloquesService();