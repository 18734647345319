import axios from 'axios';
import API_URL from '../backend'
import authHeader from './auth-header.service';


class ServiciosService {
    getTodos(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/servicios/todos',
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    actualizarServicio(servicio){
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/admin/servicios/update',
            data:servicio,
            headers: authHeader()
        }
        console.log(axiosRequest);
        return axios(axiosRequest);
    }
    nuevoServicio(servicio){
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/admin/servicios/nuevo',
            data:servicio,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    borrarServicio(servicioId){
        const axiosRequest = {
            method: 'delete',
            url: API_URL+'/admin/servicios/borrar/'+servicioId,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }

}
export default new ServiciosService();